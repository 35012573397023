var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from 'events';
import { commonPostMessage } from '@codesandbox/common/lib/utils/global';
import { protocolAndHost } from '@codesandbox/common/lib/utils/url-generator';
var SOCKET_IDENTIFIER = 'node-socket';
var Socket = /** @class */ (function (_super) {
    __extends(Socket, _super);
    function Socket(target, channel, isWorker) {
        var _this = _super.call(this) || this;
        _this.target = target;
        _this.channel = channel;
        _this.isWorker = isWorker;
        _this.destroyed = false;
        _this.emit('connect');
        _this.startListening();
        return _this;
    }
    Socket.prototype.setEncoding = function (encoding) {
        this.encoding = encoding;
    };
    Socket.prototype.defaultListener = function (e) {
        var evt = e;
        if ((evt.source || self) !== this.target) {
            return;
        }
        var data = evt.data.$data;
        if (!data) {
            return;
        }
        if (data.$type === SOCKET_IDENTIFIER && data.$channel === this.channel) {
            this.emit('data', Buffer.from(JSON.parse(data.data)));
        }
    };
    Socket.prototype.startListening = function () {
        self.addEventListener('message', this.defaultListener.bind(this));
    };
    Socket.prototype.end = function () {
        self.removeEventListener('message', this.defaultListener.bind(this));
        this.destroyed = true;
        // @ts-ignore
        if (typeof this.target.terminate !== 'undefined') {
            // @ts-ignore
            this.target.terminate();
        }
    };
    Socket.prototype.unref = function () { };
    Socket.prototype.write = function (buffer) {
        if (this.destroyed) {
            return;
        }
        var message = {
            $type: SOCKET_IDENTIFIER,
            $channel: this.channel,
            data: JSON.stringify(buffer),
        };
        if (this.isWorker) {
            this.target.postMessage(message);
        }
        else {
            this.target.postMessage(message, protocolAndHost());
        }
    };
    return Socket;
}(EventEmitter));
export { Socket };
var Server = /** @class */ (function (_super) {
    __extends(Server, _super);
    function Server() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.connected = false;
        _this.closed = false;
        _this.socket = null;
        _this.listenerFunctions = [];
        return _this;
    }
    Server.prototype.listen = function (listenPath, listenCallback) {
        var _this = this;
        var listenerFunction = function (e) {
            var data = e.data.$data || e.data;
            if (_this.closed) {
                return;
            }
            if (data.$type === 'node-server' &&
                data.$channel === listenPath &&
                data.$event === 'init') {
                _this.connected = true;
                _this.socket = new Socket(e.source || self, listenPath, false);
                _this.emit('connection', _this.socket);
            }
        };
        this.listenerFunctions.push(listenerFunction);
        self.addEventListener('message', listenerFunction);
        if (listenCallback) {
            listenCallback();
        }
    };
    Server.prototype.close = function (cb) {
        this.closed = true;
        this.removeAllListeners();
        // This is not according to spec, but we do this anyways for clean cleanup
        this.listenerFunctions.forEach(function (func) {
            self.removeEventListener('message', func);
        });
        if (cb) {
            cb();
        }
    };
    return Server;
}(EventEmitter));
export { Server };
function blobToBuffer(blob, cb) {
    if (typeof Blob === 'undefined' || !(blob instanceof Blob)) {
        throw new Error('first argument must be a Blob');
    }
    if (typeof cb !== 'function') {
        throw new Error('second argument must be a function');
    }
    var reader = new FileReader();
    function onLoadEnd(e) {
        reader.removeEventListener('loadend', onLoadEnd, false);
        if (e.error) {
            cb(e.error);
        }
        else {
            // @ts-ignore
            cb(null, Buffer.from(reader.result));
        }
    }
    reader.addEventListener('loadend', onLoadEnd, false);
    reader.readAsArrayBuffer(blob);
}
var WebSocketServer = /** @class */ (function (_super) {
    __extends(WebSocketServer, _super);
    function WebSocketServer(url) {
        var _this = _super.call(this) || this;
        _this.url = url;
        _this.connected = false;
        _this.closed = false;
        _this.socket = null;
        _this.listenerFunctions = [];
        return _this;
    }
    WebSocketServer.prototype.listen = function (listenPath, listenCallback) {
        var _this = this;
        this.socket = new WebSocket(this.url);
        this.socket.onmessage = function (message) {
            blobToBuffer(message.data, function (err, r) {
                _this.emit('data', r);
            });
        };
        this.socket.onclose = function () {
            _this.emit('close');
        };
        if (listenCallback) {
            listenCallback();
        }
        this.socket.onopen = function () {
            _this.connected = true;
            _this.emit('connection', _this);
        };
    };
    WebSocketServer.prototype.write = function (buffer) {
        this.socket.send(buffer);
    };
    WebSocketServer.prototype.end = function () {
        this.socket.close();
    };
    WebSocketServer.prototype.close = function (cb) {
        this.closed = true;
        this.removeAllListeners();
        // This is not according to spec, but we do this anyways for clean cleanup
        this.listenerFunctions.forEach(function (func) {
            self.removeEventListener('message', func);
        });
        if (cb) {
            cb();
        }
    };
    return WebSocketServer;
}(EventEmitter));
export { WebSocketServer };
var socketUrl = '';
function setSocketURL(url) {
    socketUrl = url;
}
function createServerWS() {
    return new WebSocketServer(socketUrl);
}
function createServerLocal() {
    return new Server();
}
function createServer() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (socketUrl) {
        return createServerWS();
    }
    else {
        return createServerLocal();
    }
}
function createConnection(pipeName, cb) {
    commonPostMessage({
        $type: 'node-server',
        $channel: pipeName,
        $event: 'init',
    });
    var socket = new Socket(self, pipeName, true);
    setTimeout(function () {
        if (cb) {
            cb();
        }
    }, 0);
    // TODO: Fix this to initialize properly
    return socket;
}
var connect = createConnection;
export { setSocketURL, createServer, createConnection, connect };

import * as resolve from 'resolve';
import * as path from 'path';
import { getCaller, getCallSites } from './stubs/resolve-caller';
function getConstants() {
    var constants = require('constants-browserify');
    constants.FS = BrowserFS.BFSRequire('fs');
    return constants;
}
function updateChildren(parent, child, scan) {
    var children = parent && parent.children;
    if (children && !(scan && children.includes(child)))
        children.push(child);
}
var Module = /** @class */ (function () {
    function Module(id, parent) {
        this.id = id;
        this.exports = {};
        this.parent = parent;
        updateChildren(parent, this, false);
        this.filename = null;
        this.loaded = false;
        this.children = [];
    }
    Module.prototype.load = function (filename) {
        if (this.loaded) {
            throw new Error('Loaded already');
        }
        this.filename = filename;
        var extension = path.extname(filename) || '.js';
        if (!Module._extensions[extension])
            extension = '.js';
        Module._extensions[extension](this, filename);
        this.loaded = true;
    };
    Module.prototype.require = function (path) {
        return Module._load(path, this);
    };
    Module.prototype._compile = function (content, filename) {
        var _self = this;
        // remove shebang
        content = content.replace(/^\#\!.*/, '');
        function require(path) {
            return _self.require(path);
        }
        require.cache = Module._cache;
        require.resolve = function (request) {
            return Module._resolveFilename(request, _self);
        };
        var global = {
            require: require,
            exports: _self.exports,
            __filename: filename,
            __dirname: path.dirname(filename),
            module: _self,
        };
        global.global = self;
        Module.evaluate(global, [content, "//# sourceURL=".concat(location.origin).concat(filename)].join('\n'));
    };
    Module.evaluate = function (global, content) {
        // Don't let the module think that we're in an AMD env.
        var oldamd = self.define;
        self.define = null;
        var globalParams = Object.keys(global).join(', ');
        var newCode = ["(function(".concat(globalParams, ") {"), content, "})"].join('\n');
        var result = (0, eval)(newCode).apply(this, Object.keys(global).map(function (m) { return global[m]; }));
        self.define = oldamd;
        return result;
    };
    Module._resolveFilename = function (request, parent) {
        return resolve.sync(request, {
            basedir: parent.filename ? path.dirname(parent.filename) : undefined,
            extensions: ['.js', '.json'],
        });
    };
    Module.createRequire = function (fromPath) {
        var module = new Module(fromPath);
        module.filename = fromPath;
        return module._createRequire();
    };
    Module.prototype._createRequire = function () {
        var _this = this;
        var require = function (p) { return _this.require(p); };
        require.resolve = function (request) {
            return Module._resolveFilename(request, _this);
        };
        return require;
    };
    Module._load = function (request, parent, isMain) {
        if (request === 'module') {
            return Module;
        }
        if (request === 'child_process') {
            return require('./child_process');
        }
        if (request === 'net') {
            return require('./net');
        }
        if (request === 'assert') {
            return require('assert');
        }
        if (request === 'string_decoder') {
            return require('string_decoder');
        }
        if (request === 'diagnostics') {
            return require('debug');
        }
        if (request === '/vscode/node_modules.asar/vscode-textmate') {
            return require('vscode-textmate/out/main');
        }
        if (request === 'zlib') {
            return require('browserify-zlib');
        }
        if (request === 'punycode') {
            return require('punycode');
        }
        if (request === 'execa') {
            return {};
        }
        if (request === 'tty') {
            return { isatty: function () { return false; } };
        }
        if (request === 'stream') {
            return require('stream-browserify');
        }
        if (request === 'http') {
            // @ts-ignore Trick the module in thinking that it's on window, it's an outdated check
            self.window = self;
            return require('http-browserify');
        }
        if (request === 'https') {
            // @ts-ignore Trick the module in thinking that it's on window, it's an outdated check
            self.window = self;
            return require('https-browserify');
        }
        if (request === 'constants') {
            return getConstants();
        }
        if (request.startsWith('vscode-extension-telemetry')) {
            return {
                default: /** @class */ (function () {
                    function TelemetryService(extensionId, extensionVersion, key) {
                    }
                    TelemetryService.prototype.sendTelemetryEvent = function (eventName, properties, measurements) { };
                    TelemetryService.prototype.dispose = function () { };
                    return TelemetryService;
                }()),
            };
        }
        if (request === 'url') {
            return require('url');
        }
        if (request === 'crypto') {
            return require('crypto');
        }
        if (request === 'util') {
            // Direct import to get the right version for VIM extensions
            return require('../node_modules/util');
        }
        if (request === 'os') {
            return require('os');
        }
        if (request === 'events') {
            return require('events');
        }
        if (request === 'readline') {
            return require('./readline');
        }
        if (request === 'stream') {
            return require('stream');
        }
        if (request === 'https-proxy-agent') {
            return {};
        }
        if (request === 'http-proxy-agent') {
            return {};
        }
        if (request === 'tls') {
            return {};
        }
        if (request === 'fs' || request === 'graceful-fs') {
            var fs_1 = BrowserFS.BFSRequire('fs');
            fs_1.constants = getConstants();
            // VIM Mode needs this just to check if there are permissions, we know that all files can be accessed
            // so this way we force it to work.
            fs_1.accessSync = function (_path, _type) {
                if (fs_1.existsSync(_path)) {
                    return true;
                }
                throw new Error('File not found');
            };
            fs_1.access = function (_path, cb1, cb) {
                var callback = cb || cb1;
                // TODO: make this async
                if (fs_1.existsSync(_path)) {
                    return callback();
                }
                callback(new Error('File not found'));
            };
            return fs_1;
        }
        // NativeModule override
        if (BrowserFS.BFSRequire(request)) {
            return BrowserFS.BFSRequire(request);
        }
        var filename = Module._resolveFilename(request, parent);
        var cachedModule = Module._cache[filename];
        if (cachedModule) {
            updateChildren(parent, cachedModule, true);
            return cachedModule.exports;
        }
        if (filename === 'callsites') {
            return getCallSites;
        }
        if (filename.endsWith('resolve/lib/caller.js')) {
            return getCaller;
        }
        var module = new Module(filename, parent);
        if (isMain) {
            // @ts-ignore
            process.mainModule = module;
            module.id = '.';
        }
        Module._cache[filename] = module;
        var threw = true;
        try {
            module.load(filename);
            threw = false;
        }
        finally {
            if (threw) {
                delete Module._cache[filename];
                console.error(module, 'had trouble loading...');
            }
        }
        return module.exports;
    };
    Module._extensions = {
        '.js': function (module, filename) {
            var fs = BrowserFS.BFSRequire('fs');
            var content = fs.readFileSync(filename, 'utf8');
            module._compile(content, filename);
        },
        '.json': function (module, filename) {
            var fs = BrowserFS.BFSRequire('fs');
            var content = fs.readFileSync(filename, 'utf8');
            try {
                module.exports = JSON.parse(content);
            }
            catch (err) {
                err.message = filename + ': ' + err.message;
                throw err;
            }
        },
    };
    Module.globalPaths = [];
    Module._cache = {};
    return Module;
}());
export default Module;

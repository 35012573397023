var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// eslint-disable-next-line max-classes-per-file
import { EventEmitter } from 'events';
import _debug from '@codesandbox/common/lib/utils/debug';
import { commonPostMessage } from '@codesandbox/common/lib/utils/global';
import { protocolAndHost } from '@codesandbox/common/lib/utils/url-generator';
var debug = _debug('cs:node:child_process');
var isSafari = typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var DefaultForkHandler;
var forkHandlerMap = new Map();
function addDefaultForkHandler(forkHandler) {
    DefaultForkHandler = forkHandler;
}
function addForkHandler(path, forkHandler) {
    forkHandlerMap.set(path, forkHandler);
}
var Stream = /** @class */ (function (_super) {
    __extends(Stream, _super);
    function Stream(forkHandler) {
        var _this = _super.call(this) || this;
        _this.forkHandler = forkHandler;
        return _this;
    }
    Stream.prototype.setEncoding = function () { };
    Stream.prototype.write = function (message) {
        this.forkHandler.postMessage({ $type: 'input-write', $data: message });
    };
    return Stream;
}(EventEmitter));
var NullStream = /** @class */ (function (_super) {
    __extends(NullStream, _super);
    function NullStream() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NullStream.prototype.setEncoding = function () { };
    return NullStream;
}(EventEmitter));
var NullChildProcess = /** @class */ (function (_super) {
    __extends(NullChildProcess, _super);
    function NullChildProcess() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stdout = new NullStream();
        _this.stderr = new NullStream();
        _this.stdin = new NullStream();
        return _this;
    }
    NullChildProcess.prototype.kill = function () { };
    return NullChildProcess;
}(EventEmitter));
var ChildProcess = /** @class */ (function (_super) {
    __extends(ChildProcess, _super);
    function ChildProcess(forkHandler) {
        var _this = _super.call(this) || this;
        _this.forkHandler = forkHandler;
        _this.destroyed = false;
        _this.stdout = new Stream(forkHandler);
        _this.stderr = new Stream(forkHandler);
        _this.stdin = new Stream(forkHandler);
        _this.listen();
        return _this;
    }
    ChildProcess.prototype.send = function (message, _a, _b, callback) {
        if (this.destroyed) {
            callback(new Error('This connection has been killed'));
            return;
        }
        var m = {
            $type: 'message',
            $data: JSON.stringify(message),
        };
        this.forkHandler.postMessage(m);
        if (typeof _a === 'function') {
            _a(null);
        }
        else if (typeof _b === 'function') {
            _b(null);
        }
        else if (typeof callback === 'function') {
            callback(null);
        }
    };
    ChildProcess.prototype.kill = function () {
        this.destroyed = true;
        this.forkHandler.removeEventListener('message', this.listener.bind(this));
        this.forkHandler.terminate();
    };
    ChildProcess.prototype.listener = function (message) {
        var data = message.data.$data;
        if (data) {
            switch (message.data.$type) {
                case 'stdout':
                    this.stdout.emit('data', data);
                    break;
                case 'message':
                    this.emit('message', JSON.parse(data));
                    break;
                default:
                    break;
            }
        }
    };
    ChildProcess.prototype.listen = function () {
        this.forkHandler.addEventListener('message', this.listener.bind(this));
    };
    return ChildProcess;
}(EventEmitter));
var cachedForkHandlers = {};
var cachedDefaultForkHandlers = [];
function getForkHandler(path) {
    var ForkHandlerConstructor = forkHandlerMap.get(path);
    if (!ForkHandlerConstructor) {
        ForkHandlerConstructor = DefaultForkHandler;
        // Explicitly ignore
        if (ForkHandlerConstructor === false) {
            return false;
        }
        if (ForkHandlerConstructor == null) {
            throw new Error('No worker set for path: ' + path);
        }
    }
    return ForkHandlerConstructor();
}
function getForkHandlerFromCache(path, isDefaultForkHandler) {
    if (isDefaultForkHandler) {
        var cachedDefaultForkHandler = cachedDefaultForkHandlers.pop();
        if (cachedDefaultForkHandler) {
            return cachedDefaultForkHandler;
        }
    }
    else if (cachedForkHandlers[path]) {
        return cachedForkHandlers[path].pop();
    }
    return undefined;
}
var sentBroadcasts = new Map();
/**
 * Broadcasts a message if it hasn't been sent by this worker/window before
 */
function handleBroadcast(path, target, data) {
    var sentBroadcastsForPath = sentBroadcasts.get(path) || [];
    if (data.$id && sentBroadcastsForPath.indexOf(data.$id) > -1) {
        return;
    }
    data.$id = data.$id || Math.floor(Math.random() * 100000000);
    if (sentBroadcastsForPath.length > 100) {
        sentBroadcastsForPath.shift();
    }
    sentBroadcastsForPath.push(data.$id);
    if (typeof Window !== 'undefined' && target instanceof Window) {
        target.postMessage(data, protocolAndHost());
    }
    else {
        target.postMessage(data);
    }
    sentBroadcasts.set(path, sentBroadcastsForPath);
}
function fork(path, argv, processOpts) {
    var ForkHandlerConstructor = forkHandlerMap.get(path);
    var isDefaultForkHandler = !ForkHandlerConstructor;
    var forkHandler = getForkHandlerFromCache(path, isDefaultForkHandler) || getForkHandler(path);
    if (forkHandler === false) {
        return new NullChildProcess();
    }
    debug('Forking', path);
    var FORK_HANDLER_ID = path + '-' + Math.floor(Math.random() * 100000);
    self.addEventListener('message', (function (e) {
        var data = e.data;
        if (data.$broadcast) {
            handleBroadcast(FORK_HANDLER_ID, forkHandler, data);
            return;
        }
        if (!data.$sang && data.$type) {
            var newData = {
                $sang: true,
                $data: data,
            };
            if (data.$data) {
                // console.log('IN', JSON.stringify(JSON.parse(data.$data), null, 2));
            }
            forkHandler.postMessage(newData);
        }
    }));
    forkHandler.addEventListener('message', function (e) {
        var data = e.data;
        if (data.$broadcast) {
            handleBroadcast(FORK_HANDLER_ID, self, data);
            return;
        }
        if (!data.$sang && data.$type) {
            var newData = {
                $sang: true,
                $data: data,
            };
            if (data.$data && data.$data) {
                /*
                try {
                  const output =
                    typeof data.$data === 'string'
                      ? data.$data
                      : new TextDecoder('utf-8').decode(data.$data) || '';
                  const json = output.split('\n').find(line => line[0] === '{');
                  console.log('OUT', JSON.stringify(JSON.parse(json || '{}'), null, 2));
                } catch (error) {
                  try {
                    console.log(
                      'OUT NO CONTENT LENGTH',
                      JSON.stringify(JSON.parse(data.$data), null, 2)
                    );
                  } catch (error) {
                    console.log('OUT ERROR', data.$data);
                  }
                }
                */
            }
            commonPostMessage(newData);
        }
    });
    var data = {
        entry: path,
        argv: argv || [],
    };
    if (processOpts) {
        data.env = processOpts.env;
        data.cwd = processOpts.cwd;
        data.execArgv = processOpts.execArgv;
    }
    if (isSafari) {
        // For Safari it takes a while until the worker started, so we listen for ready message
        // and send a message anyway if a second passes
        var sentReady_1 = false;
        var timeout_1 = setTimeout(function () {
            if (!sentReady_1) {
                forkHandler.postMessage({
                    $type: 'worker-manager',
                    $event: 'init',
                    data: data,
                });
                sentReady_1 = true;
            }
        }, 1500);
        forkHandler.addEventListener('message', function (e) {
            if (!sentReady_1 && e.data && e.data.$type === 'ready') {
                forkHandler.postMessage({
                    $type: 'worker-manager',
                    $event: 'init',
                    data: data,
                });
                clearTimeout(timeout_1);
                sentReady_1 = true;
            }
        });
    }
    else {
        forkHandler.postMessage({
            $type: 'worker-manager',
            $event: 'init',
            data: data,
        });
    }
    return new ChildProcess(forkHandler);
}
function preloadForkHandler(path) {
    var ForkHandlerConstructor = forkHandlerMap.get(path);
    var isDefaultForkHandler = !ForkHandlerConstructor;
    var worker = getForkHandler(path);
    if (isDefaultForkHandler) {
        cachedDefaultForkHandlers.push(worker);
    }
    else {
        cachedForkHandlers[path] = cachedForkHandlers[path] || [];
        cachedForkHandlers[path].push(worker);
    }
}
function execFileSync(path) {
    if (process.env.NODE_ENV === 'development') {
        debug('EXEC_FILE_SYNC', path);
    }
}
function execSync(path) {
    if (process.env.NODE_ENV === 'development') {
        debug('EXEC_SYNC', path);
    }
}
export { addForkHandler, addDefaultForkHandler, preloadForkHandler, fork, execSync, execFileSync, };
